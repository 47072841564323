module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-93},
    },{
      plugin: require('../node_modules/gatsby-plugin-fixhash/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":20,"scrollToOptions":{"behavior":"smooth"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NJN5V5T9PL"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    }]
